
import { Component, Vue } from 'vue-property-decorator';
import publicForm from './from-itm.vue';
import { loginServe } from '@/api/installServer';

@Component({
  components: { publicForm },
})
export default class BusinessRegistration extends Vue {
  formObj: any = [
    {
      input: true, //是input
      label: '企业名称', //字段
      prop: 'companyName', //字段名
      placeholder: '请填写企业名称', //提示内容
      width: 24, //参考el-col
      disabled: false, //是否禁用
      rules: [
        {
          required: true,
          message: '请填写企业名称',
        },
      ], //验证
    },
    {
      select: true, //是input
      label: '行业类别', //字段
      prop: 'businessType', //字段名
      placeholder: '请选择行业类别', //提示内容
      width: 24, //参考el-col
      disabled: false, //是否禁用
      options: [
        {
          label: '农、林、牧、渔业',
          value: '农、林、牧、渔业',
        },
        {
          label: '采矿业',
          value: '采矿业',
        },
        {
          label: '制造业',
          value: '制造业',
        },
        {
          label: '电力、燃气及水的生产和供应业',
          value: '电力、燃气及水的生产和供应业',
        },
        {
          label: '建筑业',
          value: '建筑业',
        },
        {
          label: '交通运输、仓储和邮政业',
          value: '交通运输、仓储和邮政业',
        },
        {
          label: '信息传输、计算机服务和软件业',
          value: '信息传输、计算机服务和软件业',
        },
        {
          label: '批发和零售业',
          value: '批发和零售业',
        },
        {
          label: '住宿和餐饮业',
          value: '住宿和餐饮业',
        },
        {
          label: '金融、保险业',
          value: '金融、保险业',
        },
        {
          label: '房地产业',
          value: '房地产业',
        },
        {
          label: '租赁和商务服务业',
          value: '租赁和商务服务业',
        },
        {
          label: '传播与文化产业',
          value: '传播与文化产业',
        },
        {
          label: '科学研究、技术服务和地质勘查业',
          value: '科学研究、技术服务和地质勘查业',
        },
        {
          label: '综合类',
          value: '综合类',
        },
      ],
    },
    {
      input: true, //是input
      label: '联系人', //字段
      prop: 'linkName', //字段名
      placeholder: '请填写联系人', //提示内容
      width: 24, //参考el-col
      disabled: false, //是否禁用
      rules: [
        {
          required: true,
          message: '请填写联系人姓名',
        },
      ], //验证
    },
    {
      input: true, //是input
      label: '联系电话', //字段
      prop: 'linkPhone', //字段名
      placeholder: '请填写联系电话', //提示内容
      width: 24, //参考el-col
      disabled: false, //是否禁用
      unit: '需要发送短信，请填写真实的手机号码',
      type: 'number',
      rules: [
        {
          required: true,
          message: '请填写联系电话',
        },
        {
          validator: (rule, value, callback) => {
            if (value === '') {
              callback(new Error('请输入联系电话'));
            } else {
              const regex = /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/;
              if (!value || !regex.test(value)) {
                // console.log(13123);
                callback(new Error('请输入正确的手机号码'));
              }
              callback();
            }
          },
          trigger: 'blur',
        },
      ], //验证
    },
    {
      input: true, //是input
      label: '电子邮件', //字段
      prop: 'email', //字段名
      placeholder: '请填写电子邮件', //提示内容
      width: 24, //参考el-col
      disabled: false, //是否禁用
      unit: '需要发送邮件，请填写真实的电子邮箱地址',
      rules: [
        {
          required: true,
          message: '请填写电子邮件',
        },
        {
          validator: (rule, value, callback) => {
            if (value === '') {
              callback(new Error('请填写电子邮件'));
            } else {
              const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
              if (!value || !regex.test(value)) {
                console.log(13123);
                callback(new Error('请输入正确的邮箱'));
              }
              callback();
            }
          },
          trigger: 'blur',
        },
      ], //验证
    },
  ];

  formData: any = {
    linkPhone: '',
    companyName: '',
    businessType: '',
    linkName: '',
    email: '',
  };

  onSubmit() {
    // console.log(this.formData);
    // return;
    //判断表单验证是否通过
    if ((this.$refs as any).publicForm.submitForm()) {
      loginServe
        .userRegistration(this.formData)
        .then((res: any) => {
          if (res.code == 200) {
            this.$message.success('注册成功！');
            this.$router.push('/index');
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch(() => {
          this.$message.error('注册失败，请重试！');
        });
    } else {
      // console.log('error');
    }
  }

  goBack() {
    this.$router.push('/index');
  }
}
